export const siteData = {
  "name": "SARIFIA",
  "address": "12 AVENUE DE MERMOZ",
  "postCode": "44000",
  "city": "NANTES",
  "phone": "0325453325",
  "url": "https://sarifia.pro",
  "urlSub": "sarifia.pro",
  "price": "price_1QU8Xi06NMRl1aKotM6Kq4aF",
  "pk": "pk_live_51QU8PU06NMRl1aKomlm2LJEG335mfellf4fI3IttYBpHSM4T9RCPc8ptBUMexZHyZ9Qh6bTJEV10xqqDYvF3Y80r00t7UIFh9M",
  "sk": "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"
};